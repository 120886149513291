import React from "react";
import { Formik, Form } from "formik";
import { IAgencyInfo } from "src/types/types";
import { InputField } from "src/components/form/InputField";
import { merge } from "lodash-es";
import { AgencyLogoUploader } from "./AgencyLogoUploader";
import { PhoneField } from "src/components/form/PhoneField";
import { useOvermind } from "src/state";

type Props = {};

const initialValues: IAgencyInfo = {
  address: "",
  agencyCode: "",
  agencyName: "",
  email: "",
  phone: "",
};

export const AgencyInformationForm: React.FC<Props> = () => {
  const { state } = useOvermind();

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="main-card mb-3 card">
          <div className="card-header">Agency Information</div>
          <div className="card-body">
            <Formik
              enableReinitialize
              initialValues={merge(
                {},
                initialValues,
                state.auth.user?.agencyInfo
              )}
              onSubmit={(values) => console.log(values)}
            >
              <Form>
                <div className="row">
                  <div className="col-md-8">
                    <InputField label="Agency Name" name="agencyName" />
                    <InputField label="Tax Office" name="vkoffice" />
                    <InputField label="Tax Number" name="vkno" />
                    <div className="row">
                      <div className="col-md-6">
                        <InputField label="Email" name="email" />
                      </div>
                      <div className="col-md-6">
                        <PhoneField label="Tel" name="phone" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-3">
                    <AgencyLogoUploader />
                    <div>
                      <button
                        type="button"
                        className="mb-2 mr-2 btn btn-block btn-success"
                      >
                        <strong>
                          UPGRADE for <s>$65</s> $49
                        </strong>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group justify-content-end">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
