import { api } from "src/services/api";
import { IUserService, IService, IServiceCredential } from "./_service.types";

type FetchServices = Record<string, IUserService[]> & { all: IService[] };

export const fetchServices = async (): Promise<FetchServices | false> => {
  try {
    const { data } = await api().get<{ services: FetchServices }>(
      "adminservices"
    );
    return data.services;
  } catch {
    return false;
  }
};

export const createCredentials = async (
  credential: IServiceCredential
): Promise<IUserService | false> => {
  try {
    const { data } = await api().post<any>("credential", credential);
    return data;
  } catch {
    return false;
  }
};

export const updateCredentials = async (
  credential: IServiceCredential
): Promise<IUserService | false> => {
  try {
    const { data } = await api().put<any>("credential", credential);
    return data;
  } catch {
    return false;
  }
};
