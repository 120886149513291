import React from "react";
import cx from "classnames";

type Props = {
  profit: number;
};

export const TotalReportCard: React.FC<Props> = ({ profit }) => {
  return (
    <div className={cx(`col-md-6`, `col-xl-3`)}>
      <div className="card mb-3 widget-content bg-midnight-bloom">
        <div className="widget-content-wrapper text-white">
          <div className="widget-content-left">
            <div className="widget-heading">Total Profit</div>
            <div className="widget-subheading">#Profit Ratio %5</div>
          </div>
          <div className="widget-content-right">
            <div className="widget-numbers text-white">
              <span>{profit} TRY</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
