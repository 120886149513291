import { IUser } from "src/types/types";
import { Derive } from "overmind";

type State = {
  user: IUser | null;
  token: string | null;
  isAuthenticated: Derive<State, boolean>;
};

export const state: State = {
  user: null,
  token: null,
  isAuthenticated: (state) => !!state.token,
};
