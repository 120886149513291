import { AsyncAction } from "overmind";
import {
  IService,
  IServiceCredential,
} from "src/state/services/_service.types";
import { omit } from "lodash-es";

export const loadServices: AsyncAction<void> = async ({ state, effects }) => {
  const payload = await effects.services.fetchServices();
  if (payload) {
    state.services.allServices = payload.all.reduce((result, service) => {
      if (!result[service.action]) {
        result[service.action] = [];
      }
      if (service.status) {
        result[service.action].push(service);
      }
      return result;
    }, {} as Record<string, IService[]>);
    state.services.userServices = omit(payload, "all");
  }
  state.ui.loaded = true;
};

export const createCredentials: AsyncAction<IServiceCredential> = async (
  { effects },
  credentials
) => {
  const payload = await effects.services.createCredentials(credentials);
  if (payload) {
    console.log(payload);
  }
};

export const updateCredentials: AsyncAction<IServiceCredential> = async (
  { effects },
  credential
) => {
  const payload = await effects.services.updateCredentials(credential);
  if (payload) {
    console.log(payload);
  }
};
