import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

export const GuestOnlyRoute: React.FC<ProtectedRouteProps> = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};
