import { IAgencyUser } from "src/types/types";
import { StateStatus } from "../_genericState.types";

type State = {
  users: Record<string, IAgencyUser>;
  status: StateStatus;
};

export const state: State = {
  users: {},
  status: {
    fetched: false,
    fetching: false,
    error: null,
  },
};
