import cx from "classnames";
import { Field, ErrorMessage, useFormikContext } from "formik";
import React from "react";

import { RegisterFormValues } from "src/pages/RegistrationPage";

type Props = {};

export const RegisterAgencyInformation: React.FC<Props> = () => {
  const formik = useFormikContext<RegisterFormValues>();
  return (
    <>
      <h2>
        <span className="step-icon">
          <i className="fa fa-building" />
        </span>
        <span className="step-number">Agency Information</span>
      </h2>
      <section>
        <div className="inner">
          <div className="form-row">
            <div
              className={cx("form-holder", "form-holder-2", {
                "has-error":
                  formik.touched.agencyInfo?.agencyName &&
                  formik.errors.agencyInfo?.agencyName
              })}
            >
              <label htmlFor="agencyname">Agency Name*</label>
              <Field
                type="text"
                placeholder="Agency name"
                className="form-control"
                name="agencyInfo.agencyName"
              />
              <ErrorMessage name="agencyInfo.agencyName" component="span" />
            </div>
          </div>
          <div className="form-row">
            <div
              className={cx("form-holder", "form-holder-2", {
                "has-error":
                  formik.touched.agencyInfo?.address &&
                  formik.errors.agencyInfo?.address
              })}
            >
              <label htmlFor="address">Address</label>
              <Field
                type="text"
                placeholder="Agency Address"
                className="form-control"
                name="agencyInfo.address"
              />
              <ErrorMessage name="agencyInfo.address" component="span" />
            </div>
          </div>
          <div className="form-row">
            <div
              className={cx("form-holder", "form-holder-2", {
                "has-error":
                  formik.touched.agencyInfo?.vkno &&
                  formik.errors.agencyInfo?.vkno
              })}
            >
              <label htmlFor="taxno">Tax No</label>
              <Field
                type="text"
                placeholder="Tax No"
                className="form-control"
                name="agencyInfo.vkno"
              />
              <ErrorMessage name="agencyInfo.vkno" component="span" />
            </div>
          </div>
          <div className="form-row">
            <div
              className={cx("form-holder", "form-holder-2", {
                "has-error":
                  formik.touched.agencyInfo?.vkoffice &&
                  formik.errors.agencyInfo?.vkoffice
              })}
            >
              <label htmlFor="taxoffice">Tax Office</label>
              <Field
                type="text"
                placeholder="Tax Office"
                className="form-control"
                name="agencyInfo.vkoffice"
              />
              <ErrorMessage name="agencyInfo.vkoffice" component="span" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
