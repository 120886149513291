import { useField, ErrorMessage } from "formik";
import React, { FC, useEffect, useState, useMemo } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import cx from "classnames";

type Props = {
  label: string;
  name: string;
  initialCode?: string;
} & NumberFormatProps;

export const PhoneField: FC<Props> = ({ label, name, initialCode = 90 }) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField(
    name
  );

  const parsedPhone = useMemo(() => {
    const trimmed = value.replace(/ /g, "");
    const number = trimmed.substring(trimmed.length - 10);
    const code = trimmed.replace(number, "").replace("+", "");
    return [code, number];
  }, [value]);

  const [code, setCode] = useState(() => parsedPhone[0]);
  const [no, setNo] = useState(() => parsedPhone[1]);

  useEffect(() => {
    if (code.length && no.length === 10) {
      setValue(`+${code}${no}`);
    } else {
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, no]);
  return (
    <>
      <div
        className={cx("input-group", {
          "invalid-input-phone": touched && error,
        })}
      >
        <div className="input-group-prepend">
          <span className="input-group-text">{label}</span>
        </div>
        <NumberFormat
          className="form-control phone-code"
          defaultValue={code}
          placeholder="code"
          maxLength={4}
          prefix="+"
          onValueChange={(values) => setCode(values.value)}
        />
        <NumberFormat
          className="form-control"
          defaultValue={no}
          format="(###) ### ## ##"
          placeholder="(___) ___ __ __"
          mask="_"
          onValueChange={(values) => {
            setNo(values.value);
          }}
          onBlur={() => setTouched(true)}
        />
        <ErrorMessage
          name={name}
          className="invalid-feedback"
          component="div"
        />
      </div>
      <br />
    </>
  );
};
