import cx from "classnames";
import React from "react";

type Props = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isDisabled: boolean;
};

export const RegisterSteps: React.FC<Props> = ({
  step,
  setStep,
  isDisabled
}) => {
  return (
    <ul role="tablist">
      <li
        role="tab"
        className={cx("first", {
          current: step === 0,
          done: step > 0
        })}
        aria-selected="false"
      >
        <button type="button" onClick={() => setStep(0)}>
          <div className="title">
            <span className="step-icon">
              <i className="fa fa-user" />
            </span>
            <span className="step-number">Account Information</span>
          </div>
        </button>
      </li>
      <li
        role="tab"
        className={cx({
          current: step === 1,
          done: step > 1
        })}
        aria-selected="true"
      >
        <button type="button" disabled={isDisabled} onClick={() => setStep(1)}>
          <span className="current-info audible"> </span>
          <div className="title">
            <span className="step-icon">
              <i className="fa fa-building" />
            </span>
            <span className="step-number">Agency Information</span>
          </div>
        </button>
      </li>
      <li
        role="tab"
        className={cx("last", {
          current: step === 2,
          done: step > 2
        })}
        aria-disabled="true"
      >
        <button
          className="next-step-button"
          type="button"
          disabled={isDisabled}
          onClick={() => setStep(2)}
        >
          <div className="title">
            <span className="step-icon">
              <i className="fa fa-address-card" />
            </span>
            <span className="step-number">Confirm Your Details</span>
          </div>
        </button>
      </li>
    </ul>
  );
};
