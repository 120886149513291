import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { MainLayout } from "src/layouts/MainLayout";
import { SourceRecord } from "src/sections/SourceRecord";
import slugify from "slugify";
import { useOvermind } from "src/state";

type Props = {} & RouteComponentProps<{ action: string; source: string }>;

export const SourceRecordPage: React.FC<Props> = (props) => {
  const {
    state: {
      services: { allServices, flattenUserServices },
    },
  } = useOvermind();

  const { action, source } = props.match.params;

  const service = allServices?.[action]?.find(
    (service) => slugify(service.title, { lower: true }) === source
  );

  if (!service) {
    return <Redirect to="/" />;
  }

  const userService = flattenUserServices.find(
    (userService) =>
      userService.key === service.key && userService.action === service.action
  );

  return (
    <MainLayout>
      <SourceRecord
        service={service}
        userService={userService}
        key={service.key}
      />
    </MainLayout>
  );
};
