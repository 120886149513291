import React from "react";
import { Switch, Router } from "react-router-dom";
import { browserHistory } from "src/routes/history";
import { HomePage } from "src/pages/HomePage";
import { LoginPage } from "src/pages/LoginPage";
import { RegistrationPage } from "src/pages/RegistrationPage";
import { SourceRecordPage } from "src/pages/SourceRecordPage";
import { ProtectedRoute } from "src/routes/components/ProtectedRoute";
import { GuestOnlyRoute } from "src/routes/components/GuestOnlyRoute";
import { AgencySettingsPage } from "src/pages/AgencySettingsPage";
import { useOvermind } from "src/state";

type Props = {};

const Routes: React.FC<Props> = () => {
  const {
    state: {
      auth: { isAuthenticated },
    },
  } = useOvermind();

  return (
    <Router history={browserHistory}>
      <Switch>
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          path="/"
          exact
          component={HomePage}
        />
        <GuestOnlyRoute
          isAuthenticated={isAuthenticated}
          path="/login"
          exact
          component={LoginPage}
        />
        <GuestOnlyRoute
          isAuthenticated={isAuthenticated}
          path="/register"
          exact
          component={RegistrationPage}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          path="/services/:action/:source"
          exact
          component={SourceRecordPage}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          path="/settings/agency"
          exact
          component={AgencySettingsPage}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
