import React from "react";
import cx from "classnames";

type Props = {
  type: string;
  className?: string;
};

export const ServiceIcon: React.FC<Props> = ({ type, className = "" }) => {
  switch (type) {
    case "flight":
      return <i className={cx("metismenu-icon pe-7s-plane", className)} />;
    case "hotel":
      return <i className={cx("metismenu-icon pe-7s-home", className)} />;
    case "car":
      return <i className={cx("metismenu-icon pe-7s-car", className)} />;
    case "transfer":
      return <i className={cx("metismenu-icon pe-7s-rocket", className)} />;
    case "activity":
      return <i className={cx("metismenu-icon pe-7s-ticket", className)} />;
    default:
      return <i className={cx("metismenu-icon pe-7s-global", className)} />;
  }
};
