export enum ServiceActions {
  activity = "activity",
  flight = "flight",
  hotel = "hotel",
  car = "car",
  transfer = "transfer",
}

export enum ServiceStatus {
  notimplemented = "not implemented",
  connected = "connected",
  passive = "passive",
}

export interface IService {
  key: string;
  title: string;
  status: boolean;
  description: string;
  action: ServiceActions;
}

export interface IServiceSummary {
  action: ServiceActions;
  title: string;
  description: string;
  status: ServiceStatus;
}

export interface IUserService {
  action: string;
  fulllink: boolean;
  key: string;
  title?: string;
  uri: string;
  status: boolean;
  credential?: IServiceCredential;
}

export interface IServiceCredential {
  source: string;
  url: string;
  content?: string;
  action: string;
  authentication?: IServiceAuthentication;
  active?: boolean;
}

export interface IServiceAuthentication {
  apikey?: IServiceAuthenticationApikey;
  oauth?: IServiceAuthenticationOAuth;
  basic?: IServiceAuthenticationBasic;
  body?: IServiceAuthenticationBody;
  header?: IServiceAuthenticationHeader;
}

export interface IServiceAuthenticationOAuth {
  version: string;
  username: string;
  password: string;
}

export interface IServiceAuthenticationApikey {
  key?: string;
  secret?: string;
  username?: string;
  password?: string;
}

export interface IServiceAuthenticationBasic {
  username: string;
  password: string;
  token: string;
}

export interface IServiceAuthenticationBody {
  username: string;
  password: string;
}

export interface IServiceAuthenticationHeader {
  username: string;
  password: string;
}

export interface IServiceItem {
  key: string;
  title: string;
  owned: boolean;
}

export interface IServiceList {
  [action: string]: IServiceItem[];
}
