import React from "react";
import { ReportPanel } from "src/module/reports/ReportPanel";
import { ProductPanel } from "src/module/products/ProductPanel";
import { ServiceListPanel } from "src/module/servicesList/ServiceListPanel";

type Props = {};

export const Dashboard: React.FC<Props> = () => {
  return (
    <div className="app-main__inner">
      <ProductPanel />
      <ReportPanel />
      <ServiceListPanel />
    </div>
  );
};
