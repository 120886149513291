import React from "react";
import { Field, useField, ErrorMessage } from "formik";
import { map } from "lodash-es";
import cx from "classnames";

type Props = {
  defaultValue?: any;
  label: string;
  name: string;
  options: { [name: string]: string | number };
};

export const SelectField: React.FC<Props> = ({
  defaultValue = 0,
  label,
  name,
  options,
}) => {
  const [, { touched, error }] = useField(name);

  return (
    <>
      <div
        className={cx("input-group", {
          "invalid-input": touched && error,
        })}
      >
        <div className="input-group-prepend">
          <span className="input-group-text">{label}</span>
        </div>
        <Field as="select" className="form-control custom-select" name={name}>
          <option value={defaultValue} disabled>
            Select {label}
          </option>
          {map(options, (value, name) => (
            <option value={value} key={name}>
              {name}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          className="invalid-feedback"
          component="div"
        />
      </div>
      <br />
    </>
  );
};
