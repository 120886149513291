import React, { useState } from "react";
import { ModalLayout } from "src/layouts/ModalLayout";
import { ICompany } from "src/types/types";
import { AgencyCompanyForm } from "./AgencyCompanyForm";
import cx from "classnames";

type Props = {
  button?: {
    className?: string;
    text: string;
  };
  company?: ICompany;
};

export const AgencyCompanyEditModal: React.FC<Props> = ({
  button,
  company,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const title = company ? company.companyName : "NEW COMPANY";

  return (
    <>
      <button
        className={cx("btn btn-default btn-edit", button?.className, {
          "btn-default btn-edit": !button?.className,
        })}
        onClick={() => setIsOpen(true)}
      >
        {button?.text ?? <i className="metismenu-icon pe-7s-note" />}
      </button>
      <ModalLayout
        size="lg"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={title}
        body={<AgencyCompanyForm company={company} />}
      />
    </>
  );
};
