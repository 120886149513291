import React, { useState } from "react";
import { Navigation } from "src/module/navigation/Navigation";
import { Drawer } from "src/module/drawer/Drawer";
import { Footer } from "src/sections/Footer";
import cx from "classnames/dedupe";
import { useWindowSize } from "src/hooks/useWindowSize";

type Props = {};

export const MainLayout: React.FC<Props> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [sideBarIsActive, setSideBarIsActive] = useState(true);

  useWindowSize(({ width }) => {
    if (width < 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  return (
    <div
      className={cx(
        "app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header",
        {
          "closed-sidebar-mobile": isMobile,
          "closed-sidebar": !sideBarIsActive || isMobile,
        }
      )}
    >
      <Navigation
        sidebarIsActive={sideBarIsActive}
        sidebarHandler={() => setSideBarIsActive((x) => !x)}
      />
      <div className="app-main">
        <Drawer />
        <div className="app-main__outer">
          {children}
          <Footer />
        </div>
      </div>
    </div>
  );
};
