import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { IAgencyUser } from "src/types/types";
import { InputField } from "src/components/form/InputField";
import { PhoneField } from "src/components/form/PhoneField";
import { CheckboxField } from "src/components/form/CheckboxField";
import { SelectField } from "src/components/form/SelectField";
import * as yup from "yup";
import { merge } from "lodash-es";
import { FormStatus, useFormStatus } from "src/hooks/useFormStatus";
import { SubmitMessage } from "src/components/form/SubmitMessage";
import { useOvermind } from "src/state";

const initialState: IAgencyUser = {
  username: "",
  firstname: "",
  lastname: "",
  useremail: "",
  userimageurl: "",
  agency: "",
  agencyid: 0,
  company: "",
  companyid: 0,
  companycode: "",
  agencycode: "",
  agencyInfo: {},
  title: "",
  phone: "",
  status: 1,
  userpwd: "",
};

const validationSchema = yup.object<Partial<IAgencyUser>>({
  agencyid: yup.number().required("Agency is required"),
  firstname: yup.string().required("First Name is required"),
  lastname: yup.string().required("Last Name is required"),
  title: yup.string().required("Title is required"),
  // phone: yup.string().required("Phone is required"),
  useremail: yup.string().email().required("Email is required"),
  companyid: yup.number().min(1, "Company is required"),
  userpwd: yup.string().when("userguid", {
    is: undefined,
    then: yup.string().required("Password is required"),
  }),
});

type Props = {
  user?: IAgencyUser;
};

export const AgencyUserForm: React.FC<Props> = ({ user }) => {
  const [formStatus, setFormStatus] = useFormStatus();
  const {
    state: {
      auth: { user: authUser },
      companies: { companyOptions },
    },
    actions: {
      users: { createUser, updateUser },
    },
  } = useOvermind();

  const submitHandler = async (
    values: IAgencyUser,
    { setSubmitting }: FormikHelpers<IAgencyUser>
  ) => {
    setSubmitting(true);
    try {
      if (values.userguid) {
        await updateUser(values);
      } else {
        await createUser(values);
      }
      setFormStatus(FormStatus.success, "User successfully saved");
    } catch (e) {
      console.log(e);
      setFormStatus(FormStatus.error, e.message);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={merge(
        { agencyid: authUser!.agencyid },
        initialState,
        user
      )}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, errors, values }) => (
        <Form>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <InputField label="title" name="title" />
                </div>
                <div className="col-md-5">
                  <InputField label="First Name" name="firstname" />
                </div>
                <div className="col-md-5">
                  <InputField label="Last Name" name="lastname" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField label="Email" name="useremail" />
                </div>
                <div className="col-md-6">
                  <InputField label="Password" name="userpwd" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <PhoneField label="Tel" name="phone" />
                </div>
                <div className="col-md-6">
                  <InputField label="Image" name="userimageurl" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectField
                    label="Company"
                    name="companyid"
                    options={companyOptions}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <CheckboxField name="status" label="Active" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="input-group justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <SubmitMessage status={formStatus} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
