import React from "react";
import { MainLayout } from "src/layouts/MainLayout";
import { AgencySettingsPanel } from "src/module/agencySettings/AgencySettingsPanel";

type Props = {};

export const AgencySettingsPage: React.FC<Props> = () => {
  return (
    <MainLayout>
      <AgencySettingsPanel />
    </MainLayout>
  );
};
