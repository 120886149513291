import { IConfig } from "overmind";
import { namespaced, merge } from "overmind/config";
import * as auth from "./auth";
import * as ui from "./ui";
import * as services from "./services";
import * as reports from "./reports";
import * as companies from "./companies";
import * as users from "./users";
import { createHook } from "overmind-react";
import { onInitialize } from "./onInitialize";

export const config = merge(
  { onInitialize },
  namespaced({
    auth,
    ui,
    services,
    reports,
    companies,
    users,
  })
);

declare module "overmind" {
  interface Config extends IConfig<typeof config> {}
}

export const useOvermind = createHook<typeof config>();
