import { ICompany } from "src/types/types";
import { StateStatus } from "../_genericState.types";
import { Derive } from "overmind";
import { reduce } from "lodash-es";

type State = {
  companies: Record<number, ICompany>;
  status: StateStatus;
  companyOptions: Derive<State, { [name: string]: number }>;
};

export const state: State = {
  companies: {},
  status: {
    fetched: false,
    fetching: false,
    error: null,
  },
  companyOptions: (state) =>
    reduce(
      state.companies,
      (output, company) => {
        output[company.companyName] = company.company_id!;
        return output;
      },
      {} as { [name: string]: number }
    ),
};
