import React from "react";
import { ErrorMessage, useField } from "formik";
import cx from "classnames";

type Props = {
  label: string;
  name: string;
};

export const CheckboxField: React.FC<Props> = ({ label, name }) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField(
    name
  );

  return (
    <>
      <div
        className={cx("custom-checkbox custom-control", {
          "invalid-input": touched && error,
        })}
        onClick={() => {
          setTouched(true);
          setValue(!value);
        }}
      >
        {console.log(value)}
        <input
          type="checkbox"
          className="custom-control-input"
          checked={value}
          onChange={() => null}
        />
        <label className="custom-control-label" htmlFor={name}>
          {label}
        </label>
        <ErrorMessage
          name={name}
          className="invalid-feedback"
          component="div"
        />
      </div>
      <br />
    </>
  );
};
