import cx from "classnames";
import { Field, ErrorMessage, useFormikContext } from "formik";
import React from "react";

import { RegisterFormValues } from "src/pages/RegistrationPage";

type Props = {};

export const RegisterAccountInformation: React.FC<Props> = () => {
  const formik = useFormikContext<RegisterFormValues>();
  return (
    <>
      <h2>
        <span className="step-icon">
          <i className="fa fa-user" />
        </span>
        <span className="step-number">Account Information</span>
      </h2>
      <section>
        <div className="inner">
          <div className="form-row">
            <div
              className={cx("form-holder", {
                "has-error": formik.touched.userpwd && formik.errors.userpwd,
              })}
            >
              <label htmlFor="firstname">First Name*</label>
              <Field
                type="text"
                placeholder="First Name"
                className="form-control"
                name="firstname"
              />
              <ErrorMessage name="firstname" component="span" />
            </div>
            <div
              className={cx("form-holder", {
                "has-error":
                  formik.touched.userpwdConfirm && formik.errors.userpwdConfirm,
              })}
            >
              <label htmlFor="lastname">Last Name*</label>
              <Field
                type="text"
                placeholder="Last Name"
                className="form-control"
                name="lastname"
              />
              <ErrorMessage name="lastname" component="span" />
            </div>
          </div>
          <div className="form-row">
            <div
              className={cx("form-holder", "form-holder-2", {
                "has-error":
                  formik.touched.useremail && formik.errors.useremail,
              })}
            >
              <label htmlFor="email">Email Address*</label>
              <Field
                type="text"
                placeholder="Your Email"
                className="form-control"
                name="useremail"
              />
              <ErrorMessage name="useremail" component="span" />
            </div>
          </div>
          <div className="form-row">
            <div
              className={cx("form-holder", {
                "has-error": formik.touched.userpwd && formik.errors.userpwd,
              })}
            >
              <label htmlFor="password">Password*</label>
              <Field
                type="password"
                placeholder="Password"
                className="form-control"
                name="userpwd"
              />
              <ErrorMessage name="userpwd" component="span" />
            </div>
            <div
              className={cx("form-holder", {
                "has-error":
                  formik.touched.userpwdConfirm && formik.errors.userpwdConfirm,
              })}
            >
              <label htmlFor="confirm_password">Confirm Password*</label>
              <Field
                type="password"
                placeholder="Confirm Password"
                className="form-control"
                name="userpwdConfirm"
              />
              <ErrorMessage name="userpwdConfirm" component="span" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
