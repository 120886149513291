import { AsyncAction } from "overmind";

export const loadReports: AsyncAction<void> = async ({ state, effects }) => {
  if (!state.reports.status.fetched || state.reports.status.error) {
    state.reports.status.fetching = true;
    state.reports.status.error = null;
    const payload = await effects.reports.fetchReports(
      state.services.userServicesActions
    );
    if (payload) {
      state.reports.reports = payload;
    } else {
      state.reports.status.error = "There was an error.";
    }
    state.reports.status.fetching = false;
    state.reports.status.fetched = true;
  }
};
