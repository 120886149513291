import React, { useEffect } from "react";
import { map } from "lodash-es";
import { ReportCard } from "./ReportCard";
import { TotalReportCard } from "./TotalReportCard";
import { Loader } from "src/components/Loader";
import { useOvermind } from "src/state";

type Props = {};

const bgColors: { [key: string]: string } = {
  activity: "bg-arielle-smile",
  flight: "bg-amy-crisp",
  hotel: "bg-premium-dark",
  car: "bg-happy-green",
  transfer: "bg-ripe-malin",
};

export const ReportPanel: React.FC<Props> = () => {
  const {
    state: {
      reports: { reports, status, totalProfit },
    },
    actions: {
      reports: { loadReports },
    },
  } = useOvermind();
  useEffect(() => {
    loadReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return status.fetched && !status.error ? (
    <div className="row">
      <TotalReportCard profit={totalProfit} />
      {map(reports, (report, action) => (
        <ReportCard
          key={action}
          bg={bgColors[action]}
          action={action}
          reports={report}
        />
      ))}
    </div>
  ) : (
    <Loader />
  );
};
