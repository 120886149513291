import React from "react";
import { AgencySettingsHeader } from "./AgencySettingsHeader";
import { AgencyInformationForm } from "./components/AgencyInformationForm";
import { AgencyProperties } from "./AgencyProperties";

type Props = {};

export const AgencySettingsPanel: React.FC<Props> = () => {
  return (
    <div className="app-main__inner">
      <AgencySettingsHeader />
      <AgencyInformationForm />
      <AgencyProperties />
    </div>
  );
};
