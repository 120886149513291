import { AsyncAction } from "overmind";

export const login: AsyncAction<{
  username: string;
  password: string;
}> = async ({ state, effects, actions }, { username, password }) => {
  const payload = await effects.auth.login(username, password);
  if (payload) {
    await actions.services.loadServices();
    state.auth.user = payload.user;
    state.auth.token = payload.token;
  }
};

export const logout: AsyncAction<void> = async ({ state, effects }) => {
  if (await effects.auth.logout()) {
    state.auth.user = null;
    state.auth.token = null;
  }
};

export const checkAuthentication: AsyncAction<void, boolean> = async ({
  state,
  effects,
}) => {
  const payload = await effects.auth.checkAuthentication();
  if (payload) {
    state.auth.user = payload.user;
    state.auth.token = payload.token;
    return true;
  } else {
    state.auth.user = payload;
    state.auth.token = payload;
    return false;
  }
};
