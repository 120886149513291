import { IUser } from "src/types/types";
import { api } from "src/services/api";

export const checkAuthentication = async (): Promise<{
  user: IUser;
  token: string;
} | null> => {
  try {
    await api().get("checks");
    return getUserStorage();
  } catch {
    clearUserStorage();
    return null;
  }
};

export const login = async (
  username: string,
  password: string
): Promise<{ user: IUser; token: string } | false> => {
  try {
    const { data } = await api().post<{ user: IUser; token: string }>("login", {
      username,
      password,
    });
    setUserStorage(data.user, data.token);
    return data;
  } catch {
    return false;
  }
};

export const logout = async (): Promise<boolean> => {
  clearUserStorage();
  await api().delete("login");
  return true;
};

function setUserStorage(user: IUser, token: string) {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("api_token", token);
}

function clearUserStorage() {
  localStorage.removeItem("user");
  localStorage.removeItem("api_token");
}

function getUserStorage(): { user: IUser; token: string } {
  const userRaw = localStorage.getItem("user");
  const token = localStorage.getItem("api_token");

  if (!userRaw || !token) {
    throw new Error("user not found");
  }
  return {
    user: JSON.parse(userRaw),
    token,
  };
}
