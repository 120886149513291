import React from "react";

type Props = {};

export const Footer: React.FC<Props> = () => {
  return (
    <div className="app-wrapper-footer">
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-right">
            © &nbsp; Copyright 2020 trippep
          </div>
        </div>
      </div>
    </div>
  );
};
