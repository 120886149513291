import React, { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import { IService, IUserService } from "src/state/services/_service.types";
import { useTab } from "src/hooks/useTab";
import { useOvermind } from "src/state";
import { credentialGenerator } from "src/utils/functions";
import { InputField } from "src/components/form/InputField";
import { CheckboxField } from "src/components/form/CheckboxField";
import { FormStatus } from "src/components/form/FormStatus";
import { SubmitButton } from "src/components/form/SubmitButton";

type Props = {
  service: IService;
  userService?: IUserService;
};

export const ApiKeyForm: React.FC<Props> = ({ userService, service }) => {
  const initialTabIndex = useMemo(() => {
    if (userService?.credential?.authentication?.apikey?.username) {
      return 1;
    } else {
      return 0;
    }
  }, [userService]);

  const { tabClasses, tabSelect } = useTab(initialTabIndex);

  const [type, setType] = useState(() =>
    userService?.credential?.authentication?.apikey?.username ? "login" : "key"
  );

  const {
    actions: {
      services: { createCredentials, updateCredentials },
    },
  } = useOvermind();

  return (
    <div>
      <Formik
        initialValues={credentialGenerator({
          action: service.action,
          source: service.key,
          authentication: {
            apikey: {
              key: "",
              secret: "",
              username: "",
              password: "",
            },
          },
          credentials: userService?.credential,
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          setSubmitting(true);
          const payload = { ...values };
          if (type === "key") {
            payload.authentication!.apikey!.username = "";
            payload.authentication!.apikey!.password = "";
          } else {
            payload.authentication!.apikey!.key = "";
            payload.authentication!.apikey!.secret = "";
          }
          try {
            if (userService) {
              await updateCredentials(payload);
            } else {
              await createCredentials(payload);
            }
            setStatus({ success: "Credentials Successfully saved." });
          } catch (e) {
            setStatus({ error: e.toString() });
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <InputField
              label="Url"
              placeholder="enter url"
              name="url"
              gutter={false}
            />

            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
              <li className="nav-item">
                <button
                  type="button"
                  role="tab"
                  className={tabClasses("nav-link", 0)}
                  onClick={() => {
                    tabSelect(0)();
                    setType("key");
                  }}
                >
                  <span>Key</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  role="tab"
                  className={tabClasses("nav-link", 1)}
                  onClick={() => {
                    tabSelect(1)();
                    setType("login");
                  }}
                >
                  <span>Login</span>
                </button>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className={tabClasses("tab-pane tabs-animation fade", 0)}
                role="tabpanel"
              >
                <InputField
                  label="Api Key"
                  placeholder="enter api key"
                  name="authentication.apikey.key"
                />
                <InputField
                  label="Api Secret"
                  placeholder="enter api secret"
                  name="authentication.apikey.secret"
                />
              </div>
              <div
                className={tabClasses("tab-pane tabs-animation fade", 1)}
                role="tabpanel"
              >
                <InputField
                  label="Username"
                  placeholder="enter username"
                  name="authentication.apikey.username"
                />
                <InputField
                  label="Password"
                  placeholder="enter password"
                  name="authentication.apikey.password"
                />
              </div>
            </div>
            <CheckboxField name="active" label="Active" />
            <div className="input-group justify-content-end">
              <SubmitButton submitting={isSubmitting} />
            </div>
            <br />
            <FormStatus status={status} />
          </Form>
        )}
      </Formik>
    </div>
  );
};
