import React from "react";

type Props = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isDisabled: boolean;
};

export const RegisterActions: React.FC<Props> = ({
  step,
  setStep,
  isDisabled
}) => {
  return (
    <ul role="menu" aria-label="Pagination">
      <li>
        {!!step && (
          <button type="button" onClick={() => setStep(x => x - 1)}>
            <i className="fa fa-arrow-left" />
          </button>
        )}
      </li>
      {step < 2 && (
        <li>
          <button
            type="button"
            disabled={isDisabled}
            onClick={() => setStep(x => x + 1)}
          >
            <i className="fa fa-arrow-right" />
          </button>
        </li>
      )}
      {step === 2 && (
        <li>
          <button type="submit">
            <i className="fa fa-save" />
          </button>
        </li>
      )}
    </ul>
  );
};
