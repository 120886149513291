import React from "react";
import { UserArea } from "./components/UserArea";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useOvermind } from "src/state";

type Props = {
  sidebarIsActive: boolean;

  sidebarHandler(): void;
};

export const Navigation: React.FC<Props> = ({
  sidebarIsActive,
  sidebarHandler,
}) => {
  const { state } = useOvermind();

  return (
    <div className="app-header header-shadow">
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ml-auto">
          <div>
            <button
              type="button"
              className={cx("hamburger close-sidebar-btn hamburger--elastic", {
                "is-active": !sidebarIsActive,
              })}
              onClick={sidebarHandler}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            type="button"
            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
          >
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6" />
            </span>
          </button>
        </span>
      </div>
      <div className="app-header__content">
        <div className="app-header-left">
          <div className="search-wrapper">
            <div className="input-holder">
              <input
                type="text"
                className="search-input"
                placeholder="Type to search"
              />
              <button className="search-icon">
                <span />
              </button>
            </div>
            <button className="close" />
          </div>
          <ul className="header-menu nav">
            <li className="nav-item">
              <a
                href={`http://test.trippep.com/login?redirect=${state.auth.token}`}
                className="nav-link"
              >
                <i className="nav-link-icon pe-7s-cart"> </i>
                Online Booking
              </a>
            </li>
            <li className="dropdown nav-item">
              <NavLink className="nav-link" to="/settings/agency">
                <i className="nav-link-icon fa fa-cog" />
                Settings
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="app-header-right">
          <UserArea />
        </div>
      </div>
    </div>
  );
};
