import { useFormikContext, ErrorMessage } from "formik";
import React from "react";
import cx from "classnames";
import { RegisterFormValues } from "src/pages/RegistrationPage";

type Props = {};

export const RegisterSummary: React.FC<Props> = () => {
  const formik = useFormikContext<RegisterFormValues>();
  return (
    <>
      <h2>
        <span className="step-icon">
          <i className="fa fa-address-card" />
        </span>
        <span className="step-number">Confirm Your Details</span>
      </h2>
      <section>
        <div className="inner">
          <h3>Agency Account Summary</h3>
          <div className="form-row table-responsive">
            <table className="table">
              <tbody>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.firstname,
                    })}
                  >
                    First Name:
                    <br />
                    <ErrorMessage name="firstname" component="span" />
                  </th>
                  <td id="firstname-val">{formik.values.firstname}</td>
                </tr>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.lastname,
                    })}
                  >
                    Last Name:
                    <br />
                    <ErrorMessage name="lastname" component="span" />
                  </th>
                  <td id="lastname-val">{formik.values.lastname}</td>
                </tr>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.useremail,
                    })}
                  >
                    Email Address:
                    <br />
                    <ErrorMessage name="useremail" component="span" />
                  </th>
                  <td id="email-val">{formik.values.useremail}</td>
                </tr>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.agencyInfo?.agencyName,
                    })}
                  >
                    Agency:
                    <br />
                    <ErrorMessage
                      name="agencyInfo.agencyName"
                      component="span"
                    />
                  </th>
                  <td id="agencyname-val">
                    {formik.values.agencyInfo.agencyName}
                  </td>
                </tr>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.agencyInfo?.address,
                    })}
                  >
                    Address:
                    <br />
                    <ErrorMessage name="agencyInfo.address" component="span" />
                  </th>
                  <td id="address-val">{formik.values.agencyInfo.address}</td>
                </tr>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.agencyInfo?.vkno,
                    })}
                  >
                    Tax No:
                    <br />
                    <ErrorMessage name="agencyInfo.vkno" component="span" />
                  </th>
                  <td id="taxno-val">{formik.values.agencyInfo.vkno}</td>
                </tr>
                <tr className="space-row">
                  <th
                    className={cx({
                      "has-error": formik.errors.agencyInfo?.vkoffice,
                    })}
                  >
                    Tax Office:
                    <br />
                    <ErrorMessage name="agencyInfo.vkoffice" component="span" />
                  </th>
                  <td id="taxoffice-val">
                    {formik.values.agencyInfo.vkoffice}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};
