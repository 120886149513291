import React from "react";
import cx from "classnames";
import { capitalize } from "lodash-es";
import { IReport } from "src/state/reports/_report.types";

type Props = {
  bg: string;
  action: string;
  reports: IReport[];
};

export const ReportCard: React.FC<Props> = ({ action, bg, reports }) => {
  const total = reports?.filter((r) => !r.action.includes("refund"))?.[0]!;
  const refund = reports?.filter((r) => r.action.includes("refund"))?.[0]!;

  const profit = (total?.amount ?? 0) - (refund?.amount ?? 0);

  return (
    <div className={cx(`col-md-6`, `col-xl-3`)}>
      <div className={cx("card mb-3 widget-content", bg)}>
        <div className="widget-content-wrapper text-white">
          <div className="widget-content-left">
            <div className="widget-heading">{capitalize(action)} Profit</div>
            <div className="widget-subheading">#Profit Ratio %5</div>
          </div>
          <div className="widget-content-right">
            <div className="widget-numbers text-white">
              <span>{profit} TRY</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
