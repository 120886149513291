import {
  IServiceCredential,
  IServiceAuthentication,
} from "src/state/services/_service.types";
import { merge } from "lodash-es";

export const safeJsonParse = <T>(str: any): T | undefined => {
  try {
    return JSON.parse(str);
  } catch {}
};

export const refreshExternalScripts = () => {
  // document.querySelectorAll(".jvectormap-tip").forEach((el) => el.remove());
  // document
  //   .querySelectorAll("[src='/assets/scripts/app.js']")
  //   .forEach((el) => el.remove());
  // const script = document.createElement("script");
  // script.src = "/assets/scripts/app.js";
  // script.async = true;
  // document.body.appendChild(script);
};

type CredentialGeneratorArguments = {
  action: string;
  source: string;
  authentication: IServiceAuthentication;
  credentials?: IServiceCredential;
};

export const credentialGenerator = ({
  action,
  source,
  authentication,
  credentials,
}: CredentialGeneratorArguments): IServiceCredential => {
  return merge(
    {
      action,
      source,
      url: "",
      active: true,
      authentication,
    },
    credentials
  );
};
