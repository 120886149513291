import React from "react";
import ReactDOM from "react-dom";
import App from "src/App";
import "src/plugins/fontAwesome";
import { Provider } from "overmind-react";
import { createOvermind } from "overmind";
import { config } from "./state";

// storageEvent();

const overmind = createOvermind(config, {
  devtools: true,
});

ReactDOM.render(
  <Provider value={overmind}>
    <App />
  </Provider>,
  document.getElementById("root")
);
