import { api } from "src/services/api";
import moment from "moment";
import { IReport } from "./_report.types";

export const fetchReports = async (
  actions: string[]
): Promise<Record<string, IReport[]> | false> => {
  try {
    const responses = await Promise.all(
      actions.map((action) => {
        return api().post<{ datas: IReport[] }>("dashboard_sales", {
          action: action,
          // company_code: "sisecam",
          currency: "TRY",
          edate: moment().format("YYYYMMDD"),
          sdate: "20190101",
        });
      })
    );
    return responses.reduce((output, res) => {
      const action = JSON.parse(res.config.data)?.action;
      if (action && res.status === 200) {
        output[action] = res.data.datas;
      }
      return output;
    }, {} as Record<string, IReport[]>);
  } catch {
    return false;
  }
};
