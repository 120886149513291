import React from "react";
import { ServiceStatus } from "src/state/services/_service.types";

type Props = {
  status: ServiceStatus;
};

export const ServiceStatusIndicator: React.FC<Props> = ({ status }) => {
  switch (status) {
    case ServiceStatus.connected:
      return <div className="badge badge-success">connected</div>;
    case ServiceStatus.passive:
      return <div className="badge badge-danger">Passive</div>;
    default:
      return <div className="badge badge-warning">Not Implemented</div>;
  }
};
