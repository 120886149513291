import { ICompany } from "src/types/types";
import { api } from "src/services/api";

export const fetchCompanies = async (): Promise<ICompany[] | false> => {
  try {
    const { data } = await api().get<ICompany[]>("agencycompany");
    return data;
  } catch {
    return false;
  }
};

export const createCompany = async (
  company: ICompany
): Promise<ICompany | false> => {
  try {
    const { data } = await api().post<ICompany>("agencycompany", company);
    return data;
  } catch {
    return false;
  }
};

export const updateCompany = async (
  company: ICompany
): Promise<ICompany | false> => {
  try {
    const { data } = await api().put<ICompany>("agencycompany", company);
    return data;
  } catch {
    return false;
  }
};
