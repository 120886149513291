import React from "react";
import { RouteComponentProps, Link, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { useOvermind } from "src/state";

const validationSchema = yup.object({
  username: yup.string().email().required(),
  password: yup.string().required(),
});

type Props = {} & RouteComponentProps;

type Credentials = {
  username: string;
  password: string;
};

export const LoginPage: React.FC<Props> = () => {
  const { actions } = useOvermind();
  const location = useLocation();

  const attemptLogin = async (
    values: Credentials,
    { setSubmitting }: FormikHelpers<Credentials>
  ) => {
    setSubmitting(true);
    await actions.auth.login(values);
    setSubmitting(false);
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
      <div className="app-main">
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="tab-content ">
              <div
                className="tab-pane tabs-animation fade show active"
                id="tab-content-0"
                role="tabpanel"
              >
                <div className="row">
                  <div className="col-md-12">
                    <img
                      src="https://trippep.com/images/logo.png"
                      width="20%"
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="main-card mb-3 card">
                      <div className="card-body">
                        {location.search === "?registered" && (
                          <div className="alert alert-success">
                            Registration is completed. You can log in.
                          </div>
                        )}
                        <h5 className="card-title">Login</h5>
                        <Formik
                          validateOnChange
                          validationSchema={validationSchema}
                          initialValues={{
                            username: "",
                            password: "",
                          }}
                          onSubmit={attemptLogin}
                        >
                          {({ errors, touched, isSubmitting }) => (
                            <Form>
                              <div
                                className={cx(
                                  "position-relative",
                                  "form-group",
                                  {
                                    "has-error":
                                      errors.username && touched.username,
                                  }
                                )}
                              >
                                <label htmlFor="useremail">Email</label>
                                <Field
                                  name="username"
                                  id="useremail"
                                  type="text"
                                  className={"form-control"}
                                />
                                <ErrorMessage
                                  name="username"
                                  className="help-block"
                                  component="span"
                                />
                              </div>
                              <div
                                className={cx(
                                  "position-relative",
                                  "form-group",
                                  {
                                    "has-error":
                                      errors.password && touched.password,
                                  }
                                )}
                              >
                                <label htmlFor="userpassword">Password</label>
                                <Field
                                  name="password"
                                  id="userpassword"
                                  type="password"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="password"
                                  className="help-block"
                                  component="span"
                                />
                              </div>
                              <div className="flex space-between align-center">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "Login"
                                  )}
                                </button>

                                <div className="position-relative ">
                                  <Link to="/register">New User</Link>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
