import React from "react";

type Props = {
  submitting: boolean;
};

export const SubmitButton: React.FC<Props> = ({ submitting }) => {
  return (
    <button className="btn btn-primary" type="submit" disabled={submitting}>
      {submitting ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        "Submit"
      )}
    </button>
  );
};
