import { StateStatus } from "../_genericState.types";
import { IReport } from "./_report.types";
import { Derive } from "overmind";
import { flatten, values } from "lodash-es";

type State = {
  reports: Record<string, IReport[]> | null;
  status: StateStatus;
  totalProfit: Derive<State, number>;
};

export const state: State = {
  reports: null,
  status: {
    fetched: false,
    fetching: false,
    error: null,
  },
  totalProfit: (state) => {
    const arrayed = flatten(values(state.reports));
    const total = arrayed.filter((r) => !r.action.includes("refund"));
    const refund = arrayed.filter((r) => r.action.includes("refund"));
    return (
      total.reduce((acc, r) => acc + r.amount, 0) -
      refund.reduce((acc, r) => acc + r.amount, 0)
    );
  },
};
