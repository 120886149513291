import React from "react";
import Modal from "react-modal";
import cx from "classnames";

const customStyles: Modal.Styles = {
  overlay: {
    zIndex: 10,
    backgroundColor: "rgba(0,0,0,0.5)",
    overflowY: "scroll",
  },
};

type Props = {
  title?: string;
  body: React.ReactNode;
  footer?: React.ReactNode;
  open: boolean;
  size?: "sm" | "md" | "lg";
  onClose(): void;
};

Modal.setAppElement("#root");

export const ModalLayout: React.FC<Props> = ({
  title,
  body,
  footer,
  open,
  size = "md",
  onClose,
}) => {
  return (
    <Modal
      isOpen={open}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={customStyles}
      className={cx("modal-dialog", {
        "modal-sm": size === "sm",
        "modal-md": size === "md",
        "modal-lg": size === "lg",
      })}
      onRequestClose={onClose}
    >
      <div className="modal-content">
        {!!title && (
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
        )}
        <div className="modal-body">{body}</div>
        {!!footer && <div className="modal-footer">{footer}</div>}
      </div>
    </Modal>
  );
};
