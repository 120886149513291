import React from "react";
import { Field, FieldAttributes, ErrorMessage, useField } from "formik";
import cx from "classnames";

type Props = FieldAttributes<any> & {
  label: string;
  gutter?: boolean;
};

export const InputField: React.FC<Props> = ({
  label,
  gutter = true,
  ...props
}) => {
  const [, { touched, error }] = useField<string>(props.name);
  return (
    <>
      <div
        className={cx("input-group", {
          "invalid-input": touched && error,
        })}
      >
        <div className="input-group-prepend">
          <span className="input-group-text">{label}</span>
        </div>
        <Field {...props} className="form-control" />
        <ErrorMessage
          name={props.name}
          className="invalid-feedback"
          component="div"
        />
      </div>
      {gutter && <br />}
    </>
  );
};

InputField.defaultProps = {
  type: "text",
};
