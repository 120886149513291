import { Formik, Form, FormikState } from "formik";
import { values } from "lodash-es";
import React, { useState } from "react";
import * as yup from "yup";

import { RegisterSteps } from "src/module/register/components/RegisterSteps";
import { RegisterSummary } from "src/module/register/components/RegisterSummary";
import { RegisterAccountInformation } from "src/module/register/components/RegisterAccountInformation";
import { RegisterAgencyInformation } from "src/module/register/components/RegisterAgencyInformation";
import { RegisterActions } from "src/module/register/components/RegisterActions";
import { api } from "src/services/api";
import { useHistory } from "react-router-dom";

type Props = {};

const initialValues = {
  // title: "MR",
  // username: "",
  firstname: "",
  lastname: "",
  useremail: "",
  userpwd: "",
  userpwdConfirm: "",
  // agency: "Travel Ser-Vis",
  agencyInfo: {
    // agencyCode: "travelservis",
    agencyName: "",
    address: "",
    // email: "info@servistravel.com.tr",
    // phone: "+90 212 291 77 91",
    city: "",
    country: "",
    vktitle: "",
    vkno: "",
    vkoffice: "",
  },
  lang: "",
};

export type RegisterFormValues = typeof initialValues;

const validationSchema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  useremail: yup.string().email().required(),
  userpwd: yup.string().required(),
  userpwdConfirm: yup
    .string()
    .required()
    .oneOf([yup.ref("userpwd")]),
  agencyInfo: yup.object({
    agencyName: yup.string().required(),
    address: yup.string().required(),
    vkno: yup.string().required(),
    vkoffice: yup.string().required(),
  }),
});

export const RegistrationPage: React.FC<Props> = () => {
  const history = useHistory();
  const [step, setStep] = useState(0);

  const isDisabled = (formState: FormikState<RegisterFormValues>) => {
    switch (step) {
      case 0:
        return Boolean(
          formState.errors.firstname ||
            formState.errors.lastname ||
            formState.errors.useremail ||
            formState.errors.userpwd ||
            formState.errors.userpwdConfirm
        );
      case 1:
        return Boolean(values(formState.errors).length);
      default:
        return false;
    }
  };

  return (
    <div className="page-content">
      <div className="wizard-v1-content">
        <div className="wizard-form">
          <p className="text-align-center">
            <img
              src="https://trippep.com/images/logo.png"
              width="40%"
              alt="logo"
            />
          </p>
          <Formik
            validateOnChange
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values) => {
              try {
                await api().post("user", values);
                history.push("/login?registered");
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {(formikState) => (
              <Form className="form-register" id="form-register">
                <div className="steps clearfix">
                  <RegisterSteps
                    step={step}
                    setStep={setStep}
                    isDisabled={isDisabled(formikState)}
                  />
                </div>

                <div className="content">
                  {step === 0 && <RegisterAccountInformation />}
                  {step === 1 && <RegisterAgencyInformation />}
                  {step === 2 && <RegisterSummary />}
                </div>
                <div className="actions clearfix">
                  <RegisterActions
                    step={step}
                    setStep={setStep}
                    isDisabled={isDisabled(formikState)}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
