import { Derive } from "overmind";
import { uniq, flatten, values, reduce } from "lodash-es";
import {
  IService,
  IUserService,
  IServiceSummary,
  ServiceStatus,
  IServiceList,
} from "./_service.types";

type State = {
  allServices: Record<string, IService[]> | null;
  userServices: Record<string, IUserService[]> | null;
  allServicesActions: Derive<State, string[]>;
  userServicesActions: Derive<State, string[]>;
  services: Derive<
    State,
    Array<{
      action: string;
      active: boolean;
    }>
  >;
  flattenAllServices: Derive<State, IService[]>;
  flattenUserServices: Derive<State, IUserService[]>;
  flattenServices: Derive<State, IServiceSummary[]>;
  serviceList: Derive<State, IServiceList>;
};

export const state: State = {
  allServices: null,
  userServices: null,
  allServicesActions: (state) =>
    uniq(flatten(values(state.allServices)).map((s) => s.action)),
  userServicesActions: (state) =>
    uniq(flatten(values(state.userServices)).map((s) => s.action)),
  services: (state) =>
    state.allServicesActions.map((action) => {
      if (state.userServicesActions.includes(action)) {
        return {
          action,
          active: true,
        };
      }
      return {
        action,
        active: false,
      };
    }),
  flattenAllServices: (state) => flatten(values(state.allServices)),
  flattenUserServices: (state) => flatten(values(state.userServices)),
  flattenServices: (state) =>
    state.flattenAllServices.map((a) => {
      const us = state.flattenUserServices.find(
        (u) => a.action === u.action && a.key === u.key
      );
      let status = "not-implemented";
      if (us) {
        status = us.status ? "connected" : "passive";
      }
      return {
        action: a.action,
        title: a.title,
        description: a.description,
        status: status as ServiceStatus,
      };
    }),
  serviceList: (state) =>
    reduce(
      state.allServices,
      (output, services, action) => {
        if (state.allServices?.[action]?.length) {
          output[action] = services.map((service) => {
            const owned = !!state.flattenUserServices.find((userv) => {
              return service.key === userv.key && userv.credential;
            });
            return {
              key: service.key,
              title: service.title,
              owned,
            };
          });
        }
        return output;
      },
      {} as IServiceList
    ),
};
