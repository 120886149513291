import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOvermind } from "src/state";

type Props = {};

export const UserArea: React.FC<Props> = () => {
  const { state, actions } = useOvermind();

  return (
    <div className="header-btn-lg pr-0">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left" />
          <div className="widget-content-left  ml-3 header-user-info">
            <div className="widget-heading">
              {state.auth.user?.firstname} {state.auth.user?.lastname}
            </div>
            <div className="widget-subheading">
              {state.auth.user?.agencyInfo?.agencyName}
            </div>
          </div>
          <div className="widget-content-right header-user-info ml-3">
            <button
              type="button"
              className="btn-shadow p-1 btn btn-primary btn-sm btn-fa"
              onClick={() => actions.auth.logout()}
            >
              <FontAwesomeIcon size="lg" icon={["fas", "sign-out"]} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
