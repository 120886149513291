import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { ICompany } from "src/types/types";
import { InputField } from "src/components/form/InputField";
import { PhoneField } from "src/components/form/PhoneField";
import * as yup from "yup";
import { isEmpty, merge } from "lodash-es";
import { useFormStatus, FormStatus } from "src/hooks/useFormStatus";
import { SubmitMessage } from "src/components/form/SubmitMessage";
import { useOvermind } from "src/state";

const initialState: ICompany = {
  companyName: "",
  vkno: "",
  vkloc: "",
  vktitle: "",
  address: "",
  city: "",
  country: "",
  email: "",
  phone: "",
  logos: [
    {
      theme: "dark",
      url: "",
    },
    {
      theme: "light",
      url: "",
    },
  ],
};

const validationSchema = yup.object({
  companyName: yup.string().required("Company Name is required"),
  vkno: yup.string().required("Tax no is required"),
  vkloc: yup.string().required("Tax Office Location is required"),
  vktitle: yup.string().required("Tax Office is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  email: yup.string().email().required("Email is required"),
  logos: yup.array().of(
    yup.object({
      url: yup
        .string()
        .url("Must be a valid url")
        .test("logo-test", "Logo is required", function (value) {
          return !(this.path === "logos[0].url" && isEmpty(value));
        }),
    })
  ),
});

type Props = {
  company?: ICompany;
};

export const AgencyCompanyForm: React.FC<Props> = ({ company }) => {
  const {
    actions: {
      companies: { createCompany, updateCompany },
    },
  } = useOvermind();

  const [formStatus, setFormStatus] = useFormStatus();
  const submitHandler = async (
    values: ICompany,
    { setSubmitting }: FormikHelpers<ICompany>
  ) => {
    setSubmitting(true);
    try {
      if (values.company_id) {
        await updateCompany(values);
      } else {
        await createCompany(values);
      }
      setFormStatus(FormStatus.success, "Company successfully saved");
    } catch (e) {
      setFormStatus(FormStatus.error, e.message);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={merge({}, initialState, company)}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, errors, values }) => (
        <Form>
          <div className="row">
            <div className="col-md-12">
              <InputField label="Company Name" name="companyName" />
              <InputField label="Address" name="address" />
              <div className="row">
                <div className="col-md-6">
                  <InputField label="City" name="city" />
                </div>
                <div className="col-md-6">
                  <InputField label="Country" name="country" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField label="Email" name="email" />
                </div>
                <div className="col-md-6">
                  <PhoneField label="Tel" name="phone" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField label="Logo" name="logos[0].url" />
                </div>
                <div className="col-md-6">
                  <InputField label="Logo Alt" name="logos[1].url" />
                </div>
              </div>
              <InputField label="Company Title" name="vktitle" />
              <InputField label="Tax Office" name="vkloc" />
              <InputField label="Tax Number" name="vkno" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="input-group justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <SubmitMessage status={formStatus} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
