import React from "react";
import { IServiceItem } from "src/state/services/_service.types";
import { NavLink } from "react-router-dom";
import slugify from "slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  action: string;
  service: IServiceItem;
};

export const ServiceLink: React.FC<Props> = ({ action, service }) => {
  return (
    <li>
      <NavLink
        className="menu-item"
        to={`/services/${action}/${slugify(service.title, {
          lower: true,
        })}`}
        activeClassName="mm-active"
      >
        <i className="metismenu-icon" />
        {service.title}
        {service.owned && <FontAwesomeIcon icon={["fas", "bookmark"]} />}
      </NavLink>
    </li>
  );
};
