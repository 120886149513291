import { useState } from "react";

export enum FormStatus {
  success = "sucess",
  error = "error",
}

export type FormStatusObject = {
  active: boolean;
  message?: string;
  status?: FormStatus;
};

export const useFormStatus = (): [
  FormStatusObject,
  (status: FormStatus, message: string) => void
] => {
  const [message, setMessage] = useState<string>();
  const [status, setStatus] = useState<FormStatus>();
  const [active, setActive] = useState(false);

  const setFormStatus = (status: FormStatus, message: string) => {
    setStatus(status);
    setMessage(message);
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 2000);
  };

  return [{ active, message, status }, setFormStatus];
};
