import React, { useEffect } from "react";
import { Loader } from "src/components/Loader";
import { AgencyCompanyTableRow } from "./AgencyCompanyTableRow";
import { useOvermind } from "src/state";
import { map } from "lodash-es";

type Props = {};

export const AgencyCompanyTable: React.FC<Props> = () => {
  const {
    state: {
      companies: { companies, status },
    },
    actions: {
      companies: { loadCompanies },
    },
  } = useOvermind();

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="table-responsive">
      <table className="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
          <tr>
            <th>Code</th>
            <th className="text-center">Name</th>
            <th className="text-center">Email</th>
            <th className="text-center">Tel</th>
            <th className="text-center">Location</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {status.fetched && !status.error ? (
            map(companies, (company) => (
              <AgencyCompanyTableRow
                key={company.companyCode}
                company={company}
              />
            ))
          ) : status.fetching ? (
            <tr>
              <td className="text-center" colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : (
            "There was an error"
          )}
        </tbody>
      </table>
    </div>
  );
};
