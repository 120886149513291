import React from "react";
import { Dashboard } from "src/sections/Dashboard";
import { MainLayout } from "src/layouts/MainLayout";

type Props = {};

export const HomePage: React.FC<Props> = () => {
  return (
    <MainLayout>
      <Dashboard />
    </MainLayout>
  );
};
