import React from "react";
import { AgencyUserTable } from "./components/AgencyUserTable";
import { AgencyCompanyTable } from "./components/AgencyCompanyTable";
import { useTab } from "src/hooks/useTab";
import { AgencyUserEditModal } from "./components/AgencyUserEditModal";
import { AgencyCompanyEditModal } from "./components/AgencyCompanyEditModal";

type Props = {};

export const AgencyProperties: React.FC<Props> = () => {
  const { tabSelect, tabClasses, tabIndex } = useTab();
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="main-card mb-3 card">
          <div className="card-header">
            <span>Agency Properties</span>
            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
              <li className="nav-item">
                <button
                  type="button"
                  role="tab"
                  className={tabClasses("nav-link", 0)}
                  onClick={tabSelect(0)}
                >
                  <span>Users</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  role="tab"
                  className={tabClasses("nav-link", 1)}
                  onClick={tabSelect(1)}
                >
                  <span>Companies</span>
                </button>
              </li>
            </ul>
          </div>
          <div className="card-header">
            {tabIndex === 0 ? (
              <AgencyUserEditModal
                button={{
                  className: "btn btn-primary",
                  text: "NEW USER",
                }}
              />
            ) : (
              <AgencyCompanyEditModal
                button={{
                  className: "btn btn-primary",
                  text: "NEW COMPANY",
                }}
              />
            )}
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div
                className={tabClasses("tab-pane tabs-animation fade", 0)}
                role="tabpanel"
              >
                <AgencyUserTable />
              </div>
              <div
                className={tabClasses("tab-pane tabs-animation fade", 1)}
                role="tabpanel"
              >
                <AgencyCompanyTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
