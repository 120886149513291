import React from "react";
import { ServiceStatusIndicator } from "./ServiceStatusIndicator";
import slugify from "slugify";
import { NavLink } from "react-router-dom";
import { useOvermind } from "src/state";

type Props = {};

export const ServiceListPanel: React.FC<Props> = () => {
  const {
    state: {
      services: { flattenServices },
    },
  } = useOvermind();

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="main-card mb-3 card">
          <div className="card-header">
            Services
            {/* <div className="btn-actions-pane-right">
              <div role="group" className="btn-group-sm btn-group">
                <button className="active btn btn-focus">Today</button>
              </div>
            </div> */}
          </div>
          <div className="table-responsive">
            <table className="align-middle mb-0 table table-borderless table-striped table-hover">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Name</th>
                  <th className="text-center">Product</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {flattenServices.map((service) => (
                  <tr key={service.title}>
                    <td className="text-center text-muted">###</td>
                    <td>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <div className="page-title-icon">
                              <i className="pe-7s-user icon-gradient bg-deep-blue "></i>
                            </div>
                          </div>
                          <div className="widget-content-left flex2">
                            <div className="widget-heading">
                              {service.title}
                            </div>
                            <div className="widget-subheading opacity-7">
                              {service.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{service.action}</td>
                    <td className="text-center">
                      <ServiceStatusIndicator status={service.status} />
                    </td>
                    <td className="text-center">
                      <NavLink
                        to={`/services/${service.action}/${slugify(
                          service.title,
                          {
                            lower: true,
                          }
                        )}`}
                      >
                        <button
                          type="button"
                          id="PopoverCustomT-1"
                          className="btn btn-primary btn-sm"
                        >
                          Details
                        </button>
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-block text-center card-footer">
            <button className="mr-2 btn-icon btn-icon-only btn btn-outline-info">
              <i className="pe-7s-refresh btn-icon-wrapper"> </i>
            </button>
            <button className="btn-wide btn btn-success">Show All</button>
          </div>
        </div>
      </div>
    </div>
  );
};
