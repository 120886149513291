import { IAgencyUser } from "src/types/types";
import { api } from "src/services/api";

export const fetchUsers = async (): Promise<IAgencyUser[] | false> => {
  try {
    const { data } = await api().get<IAgencyUser[]>("agencyuser");
    return data;
  } catch {
    return false;
  }
};

export const createUser = async (
  user: IAgencyUser
): Promise<IAgencyUser | false> => {
  try {
    const { data } = await api().post<IAgencyUser>("agencyuser", user);
    return data;
  } catch {
    return false;
  }
};

export const updateUser = async (
  user: IAgencyUser
): Promise<IAgencyUser | false> => {
  try {
    const { data } = await api().put<IAgencyUser>("agencyuser", user);
    return data;
  } catch {
    return false;
  }
};
