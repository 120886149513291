import Axios, { AxiosRequestConfig } from "axios";
import merge from "lodash-es/merge";

type ApiOptions = {
  config?: AxiosRequestConfig;
};

export const api = (options?: ApiOptions) => {
  const token = localStorage.getItem("api_token");

  const config: AxiosRequestConfig = merge(
    {
      headers: {}
    },
    options?.config
  );

  if (token) {
    config.headers["Authorization-Key"] = token;
  }

  config.baseURL = "https://hezarfenservices-dev.appspot.com";

  const instance = Axios.create(config);

  instance.interceptors.response.use(
    async response => {
      if (response.headers["authorization-key"]) {
        localStorage.setItem(
          "api_token",
          response.headers["authorization-key"]
        );
      }

      return response;
    },
    async error => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.removeItem("api_token");
        localStorage.removeItem("user");
      }
    }
  );

  return instance;
};
