import { AsyncAction } from "overmind";
import { ICompany } from "src/types/types";
import { values } from "lodash-es";

export const loadCompanies: AsyncAction<void> = async ({ state, effects }) => {
  if (!values(state.companies.companies).length) {
    state.companies.status.error = null;
    state.companies.status.fetching = true;
    const companies = await effects.companies.fetchCompanies();
    if (companies) {
      state.companies.companies = companies.reduce((output, company) => {
        output[company.company_id!] = company;

        return output;
      }, {} as Record<number, ICompany>);
    } else {
      state.companies.status.error = "Couldn't load users";
    }
    state.companies.status.fetched = true;
    state.companies.status.fetching = false;
  }
};

export const createCompany: AsyncAction<ICompany> = async (
  { state, effects },
  payload
) => {
  const company = await effects.companies.createCompany(payload);
  if (company) {
    state.companies.companies[company.company_id!] = company;
  }
};

export const updateCompany: AsyncAction<ICompany> = async (
  { state, effects },
  payload
) => {
  const company = await effects.companies.updateCompany(payload);
  if (company) {
    state.companies.companies[company.company_id!] = company;
  }
};
