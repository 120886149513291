import React from "react";
import { Formik, Form } from "formik";
import { IService, IUserService } from "src/state/services/_service.types";
import { credentialGenerator } from "src/utils/functions";
import { useOvermind } from "src/state";
import { InputField } from "../form/InputField";
import { CheckboxField } from "../form/CheckboxField";
import { SubmitButton } from "../form/SubmitButton";
import { FormStatus } from "../form/FormStatus";

type Props = {
  service: IService;
  userService?: IUserService;
};

export const HeaderLoginForm: React.FC<Props> = ({ userService, service }) => {
  const {
    actions: {
      services: { createCredentials, updateCredentials },
    },
  } = useOvermind();

  return (
    <div>
      <Formik
        initialValues={credentialGenerator({
          action: service.action,
          source: service.key,
          authentication: {
            header: {
              username: "",
              password: "",
            },
          },
          credentials: userService?.credential,
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          setSubmitting(true);
          try {
            if (userService) {
              await updateCredentials(values);
            } else {
              await createCredentials(values);
            }
            setStatus({ success: "Credentials Successfully saved." });
          } catch (e) {
            setStatus({ error: e.toString() });
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <InputField label="Url" placeholder="enter url" name="url" />
            <InputField
              label="Username"
              placeholder="enter username"
              name="authentication.header.username"
            />
            <InputField
              label="Password"
              placeholder="enter password"
              name="authentication.header.password"
            />
            <CheckboxField name="active" label="Active" />
            <div className="input-group justify-content-end">
              <SubmitButton submitting={isSubmitting} />
            </div>
            <br />
            <FormStatus status={status} />
          </Form>
        )}
      </Formik>
    </div>
  );
};
