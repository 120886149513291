import React from "react";

type Props = {};

export const AgencyLogoUploader: React.FC<Props> = () => {
  return (
    <>
      <div className="thumbnail flex center">
        <img
          src="https://via.placeholder.com/146x146"
          alt="..."
          className="img-thumbnail img-responsive center-block block-center"
        />
      </div>
      <br />
    </>
  );
};
