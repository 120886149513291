import React from "react";

type Props = {};

export const AgencySettingsHeader: React.FC<Props> = () => {
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className="page-title-icon ">
            <i className="pe-7s-settings icon-gradient bg-happy-green"> </i>
          </div>
          <div className="page-title-heading1">
            Agency Settings
            <div className="page-title-subheading">
              You can manage your account here
            </div>
          </div>
        </div>
        <div className="page-title-actions">
          <div className="d-inline-block dropdown">
            <button
              type="button"
              aria-expanded="false"
              className="btn-shadow dropdown-toggle btn btn-info"
            >
              <span className="btn-icon-wrapper pr-2 opacity-7">
                <i className="fa fa-business-time fa-w-20" />
              </span>
              Manage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
