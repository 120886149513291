import React from "react";

type Props = {
  status: any;
};

export const FormStatus: React.FC<Props> = ({ status }) => {
  return (
    <>
      {status?.success && (
        <div className="input-group justify-content-end">
          <div className="alert alert-success w-100">{status.success}</div>
        </div>
      )}
      {status?.error && (
        <div className="input-group justify-content-end">
          <div className="alert alert-danger w-100">{status.error}</div>
        </div>
      )}
    </>
  );
};
