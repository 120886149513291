import React, { useMemo } from "react";
import { OAuthForm } from "src/components/authRecordForms/OAuthForm";
import { ApiKeyForm } from "src/components/authRecordForms/ApiKeyForm";
import { BasicAuthForm } from "src/components/authRecordForms/BasicAuthForm";
import { BodyLoginForm } from "src/components/authRecordForms/BodyLoginForm";
import { HeaderLoginForm } from "src/components/authRecordForms/HeaderLoginForm";
import { useTab } from "src/hooks/useTab";
import { IService, IUserService } from "src/state/services/_service.types";
import { keys } from "lodash-es";

type Props = {
  service: IService;
  userService?: IUserService;
};

export const SourceRecord: React.FC<Props> = ({ service, userService }) => {
  const initialTabIndex = useMemo(() => {
    if (userService?.credential?.authentication) {
      for (const auth of keys(userService.credential.authentication)) {
        switch (auth) {
          case "apikey":
            return 2;
          case "oauth":
            return 0;
          case "basic":
            return 1;
          case "body":
            return 3;
          case "header":
            return 4;
          default:
            return 0;
        }
      }
    } else {
      return 0;
    }
  }, [userService]);

  const { tabClasses, tabSelect } = useTab(initialTabIndex);

  return (
    <div className="app-main__inner">
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon ">
              <i className="pe-7s-home icon-gradient bg-sunny-morning"> </i>
            </div>
            <div className="page-title-heading1">
              {service.title} Credentials
              <div className="page-title-subheading">
                Please ask your {service?.title} account responsible for live
                credentials
              </div>
            </div>
          </div>
          <div className="page-title-actions">
            <div className="d-inline-block dropdown">
              <button
                type="button"
                aria-expanded="false"
                className="btn-shadow dropdown-toggle btn btn-info"
              >
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <i className="fa fa-business-time fa-w-20" />
                </span>
                Manage
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="main-card mb-3 card" key={service.key}>
              <div className="card-body">
                <div>
                  <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                    <li className="nav-item">
                      <button
                        role="tab"
                        className={tabClasses("nav-link", 0)}
                        onClick={tabSelect(0)}
                      >
                        <span>OAuth</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        role="tab"
                        className={tabClasses("nav-link", 1)}
                        onClick={tabSelect(1)}
                      >
                        <span>Basic Auth</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        role="tab"
                        className={tabClasses("nav-link", 2)}
                        onClick={tabSelect(2)}
                      >
                        <span>Api</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        role="tab"
                        className={tabClasses("nav-link", 3)}
                        onClick={tabSelect(3)}
                      >
                        <span>Body Login</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        role="tab"
                        className={tabClasses("nav-link", 4)}
                        onClick={tabSelect(4)}
                      >
                        <span>Header Login</span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={tabClasses("tab-pane tabs-animation fade", 0)}
                      role="tabpanel"
                    >
                      <OAuthForm service={service} userService={userService} />
                    </div>
                    <div
                      className={tabClasses("tab-pane tabs-animation fade", 1)}
                      role="tabpanel"
                    >
                      <BasicAuthForm
                        service={service}
                        userService={userService}
                      />
                    </div>
                    <div
                      className={tabClasses("tab-pane tabs-animation fade", 2)}
                      role="tabpanel"
                    >
                      <ApiKeyForm service={service} userService={userService} />
                    </div>
                    <div
                      className={tabClasses("tab-pane tabs-animation fade", 3)}
                      role="tabpanel"
                    >
                      <BodyLoginForm
                        service={service}
                        userService={userService}
                      />
                    </div>
                    <div
                      className={tabClasses("tab-pane tabs-animation fade", 4)}
                      role="tabpanel"
                    >
                      <HeaderLoginForm
                        service={service}
                        userService={userService}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-50" />
    </div>
  );
};
