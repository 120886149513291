import React from "react";
import cx from "classnames";

type Props = {
  fullScreen?: boolean;
};

export const Loader: React.FC<Props> = ({ fullScreen = false }) => {
  return (
    <div
      className={cx("loader-container", {
        "is-fullscreen": fullScreen
      })}
    >
      <div className="loader">
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};
