import React from "react";
import { AgencyCompanyEditModal } from "./AgencyCompanyEditModal";
import { ICompany } from "src/types/types";

type Props = {
  company: ICompany;
};

export const AgencyCompanyTableRow: React.FC<Props> = ({ company }) => {
  return (
    <tr>
      <td>{company.companyCode}</td>
      <td className="text-center">{company.companyName}</td>
      <td className="text-center">{company.email}</td>
      <td className="text-center">{company.phone}</td>
      <td className="text-center">
        {company.city} / {company.country}
      </td>
      <td className="text-center">
        <AgencyCompanyEditModal company={company} />
      </td>
    </tr>
  );
};
