import React from "react";
import { FormStatusObject, FormStatus } from "src/hooks/useFormStatus";

type Props = {
  status: FormStatusObject;
};

export const SubmitMessage: React.FC<Props> = ({ status }) => {
  return status.active ? (
    <>
      <br />
      {status.status === FormStatus.success && (
        <div className="input-group justify-content-end">
          <div className="alert alert-success w-100">{status.message}</div>
        </div>
      )}
      {status.status === FormStatus.error && (
        <div className="input-group justify-content-end">
          <div className="alert alert-danger w-100">{status.message}</div>
        </div>
      )}
    </>
  ) : null;
};
