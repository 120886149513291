import { useState } from "react";
import cx from "classnames";

export const useTab = (index = 0) => {
  const [tabIndex, setTabIndex] = useState(index);

  const tabClasses = (className: string, idx: number) => {
    return cx(className, { "active show": tabIndex === idx });
  };

  const tabSelect = (idx: number) => () => {
    setTabIndex(idx);
  };

  return { tabClasses, tabSelect, tabIndex };
};
