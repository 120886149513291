import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";
import { map, capitalize } from "lodash-es";
import { ServiceIcon } from "src/components/ServiceIcon";
import { useOvermind } from "src/state";
import { ServiceLink } from "./components/ServiceLink";

type Props = {};

export const Drawer: React.FC<Props> = () => {
  const location = useLocation();
  const {
    state: {
      services: { serviceList },
    },
  } = useOvermind();

  const [menu, setMenu] = useState<string>();

  const isCurrent = (section: string) => {
    return (menu || location.pathname.split("/")[2]) === section;
  };

  return (
    <div className="app-sidebar sidebar-shadow">
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ml-auto">
          <div>
            <button
              type="button"
              className="hamburger close-sidebar-btn hamburger--elastic"
              data-class="closed-sidebar"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            type="button"
            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
          >
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6" />
            </span>
          </button>
        </span>
      </div>
      <div className="scrollbar-sidebar">
        <div className="app-sidebar__inner">
          <ul className="vertical-nav-menu">
            <li className="app-sidebar__heading">Dashboards</li>
            <li>
              <NavLink to="/" exact activeClassName="mm-active">
                <i className="metismenu-icon pe-7s-graph2" /> Sales &amp; Profit
              </NavLink>
            </li>
            <li className="app-sidebar__heading">Providers</li>
            {map(serviceList, (services, action) => (
              <li
                className={cx({ "mm-active": isCurrent(action) })}
                key={action}
              >
                {/*TODO: fix anchor tag*/}
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a onClick={() => setMenu(action)}>
                  <ServiceIcon type={action} /> {capitalize(action)}
                  <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                </a>
                <ul>
                  {services.map((service) => (
                    <ServiceLink
                      action={action}
                      service={service}
                      key={service.key}
                    />
                  ))}
                </ul>
              </li>
            ))}
            <li>
              <a
                href="https://trippep.com#pricing"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="metismenu-icon pe-7s-diamond" /> Upgrade to PRO
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
