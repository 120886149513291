import React, { useEffect } from "react";
import { AgencyUserTableRow } from "./AgencyUserTableRow";
import { Loader } from "src/components/Loader";
import { useOvermind } from "src/state";
import { map } from "lodash";

type Props = {};

export const AgencyUserTable: React.FC<Props> = () => {
  const {
    state: {
      users: { users, status },
    },
    actions: {
      users: { loadUsers },
    },
  } = useOvermind();
  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="table-responsive">
      <table className="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-center">Title</th>
            <th className="text-center">Email</th>
            <th className="text-center">Tel</th>
            <th className="text-center">Company</th>
            <th className="text-center">Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {status.fetched && !status.error ? (
            map(users, (user) => (
              <AgencyUserTableRow key={user.userguid!} user={user} />
            ))
          ) : status.fetching ? (
            <tr>
              <td className="text-center" colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : (
            "There was an error"
          )}
        </tbody>
      </table>
    </div>
  );
};
