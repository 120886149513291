import React from "react";
import { AgencyUserEditModal } from "./AgencyUserEditModal";
import { IAgencyUser } from "src/types/types";
import { ActiveStatus } from "src/components/ActiveStatus";

type Props = {
  user: IAgencyUser;
};

export const AgencyUserTableRow: React.FC<Props> = ({ user }) => {
  return (
    <tr>
      <td>
        {user.firstname} {user.lastname}
      </td>
      <td className="text-center">{user.title}</td>
      <td className="text-center">{user.useremail}</td>
      <td className="text-center">{user.phone}</td>
      <td className="text-center">{user.company}</td>
      <td className="text-center">
        <ActiveStatus status={user.status} />
      </td>
      <td className="text-center">
        <AgencyUserEditModal user={user!} />
      </td>
    </tr>
  );
};
