import { AsyncAction } from "overmind";
import { IAgencyUser } from "src/types/types";
import { values } from "lodash-es";

export const loadUsers: AsyncAction<void> = async ({ state, effects }) => {
  if (!values(state.users.users).length) {
    state.users.status.error = null;
    state.users.status.fetching = true;
    const users = await effects.users.fetchUsers();
    if (users) {
      state.users.users = users.reduce((output, user) => {
        output[user.userguid!] = user;

        return output;
      }, {} as Record<string, IAgencyUser>);
    } else {
      state.users.status.error = "Couldn't load users";
    }
    state.users.status.fetched = true;
    state.users.status.fetching = false;
  }
};

export const createUser: AsyncAction<IAgencyUser> = async (
  { state, effects },
  payload
) => {
  const user = await effects.users.createUser(payload);
  if (user) {
    state.users.users[user.userguid!] = user;
  }
};

export const updateUser: AsyncAction<IAgencyUser> = async (
  { state, effects },
  payload
) => {
  const user = await effects.users.updateUser(payload);
  if (user) {
    state.users.users[user.userguid!] = user;
  }
};
