import React from "react";
import cx from "classnames";
import { ServiceIcon } from "src/components/ServiceIcon";
import { useOvermind } from "src/state";

type Props = {};

export const ProductPanel: React.FC<Props> = () => {
  const {
    state: {
      services: { services },
    },
  } = useOvermind();

  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          {services.map((service, idx) => (
            <div className="page-title-icon " key={idx}>
              <ServiceIcon
                type={service.action}
                className={cx("icon-gradient", {
                  "bg-happy-green": service.active,
                  "bg-strong-bliss": !service.active,
                })}
              />
            </div>
          ))}
          <div className="page-title-heading1">
            Active Products
            <div className="page-title-subheading">
              You can easily activate your new providers
            </div>
          </div>
        </div>
        <div className="page-title-actions">
          <div className="d-inline-block dropdown">
            <button
              type="button"
              aria-expanded="false"
              className="btn-shadow dropdown-toggle btn btn-info"
            >
              <span className="btn-icon-wrapper pr-2 opacity-7">
                <i className="fa fa-business-time fa-w-20" />
              </span>
              Manage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
