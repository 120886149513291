import React from "react";
import Routes from "src/routes/Routes";
import "src/styles/custom.scss";
import { Loader } from "./components/Loader";
import { useOvermind } from "./state";

const App = () => {
  const { state } = useOvermind();
  return state.ui.loaded ? <Routes /> : <Loader fullScreen />;
};

export default App;
