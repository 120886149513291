import React from "react";
import { toNumber } from "lodash-es";

type Props = {
  status?: number;
};

export const ActiveStatus: React.FC<Props> = ({ status = 0 }) => {
  switch (toNumber(status)) {
    case 0:
      return <div className="badge badge-danger">Passive</div>;
    case 1:
      return <div className="badge badge-success">Active</div>;
    default:
      return <div className="badge badge-danger">Passive</div>;
  }
};
